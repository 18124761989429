import React, { useEffect } from 'react'
import 'twin.macro'

import SEO from 'components/shared/seo'
import useThankYouSC from 'content-queries/mx/thank-you-sc'
import fireEvent from 'context/tracking/events'
import Hero from 'mx/components/hero'
import { SectionContent } from 'mx/components/section-content'
import Layout from 'mx/layout'

const ThankYouSCPage = () => {
  const { data } = useThankYouSC()
  const [
    {
      node: {
        sectionModules: [hero, quality, functionality, knownProcess],
      },
    },
  ] = data

  useEffect(() => {
    document.body.scrollTo({ top: 0 })
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Thank You Studio',
    })
  }, [])

  return (
    <Layout>
      <Hero data={hero} graphicImage={true} />
      <h2 tw="mt-44 text-4xl w-full mx-auto text-center">
        Here's what you can expect from your upcoming visit
      </h2>
      <SectionContent data={quality} />
      <SectionContent data={functionality} />
      <SectionContent data={knownProcess} />
      <div tw="pb-44"></div>
    </Layout>
  )
}

export default ThankYouSCPage

export const Head = () => {
  return (
    <SEO
      description="Thanks for scheduling your visit."
      path="/thank-you-sc"
      title="Thank You Studio"
    />
  )
}
